
import { Component, Prop, Vue } from "vue-property-decorator";
import JFLine from "@/components/common/JFLine.vue";
import { PlanModel, SubscriptionPackageCheckBox, SubscriptionPackageType } from "@/types/localtypes";
import SubscriptionPackage from "@/components/views/subscriptions/modals/headerPurchaseProcess/SubscriptionPackage.vue";

@Component({
  name: "HeaderForPurchase",
  components: {
    JFLine,
    SubscriptionPackage,
  },
})
export default class HeaderForPurchase extends Vue {
  @Prop() private parentDataGtmPage!: string;
  @Prop({ default: false })
  private isJCR!: boolean;
  @Prop({ default: null })
  private plan!: PlanModel;

  totalPrice = "";
  firstUpdateEntXSend = false;

  get subscriptionPackages(): SubscriptionPackageType[] {
    let base: SubscriptionPackageType[] = [
      {
        id: "Base",
        title: "Billed Monthly",
        price: this.plan.purchase ? this.$utils.round(this.plan.purchase.amount) : "N/A",
        hasCheckbox: false,
        checkboxCSS: [],
        // usage: this.plan.usage,
      },
    ];

    if (this.plan.paymentType === "ENTERPRISE_MONTHLY") {
      // need to update payment type sent to store.
      if (!this.firstUpdateEntXSend) {
        this.$emit("onUpdatePaymentType", "ENTERPRISE_X_MONTHLY");
        this.firstUpdateEntXSend = true;
      }
      base = [
        {
          id: "Base",
          title: "Billed Monthly",
          price: this.plan.purchase ? this.$utils.round(this.plan.purchase.amount) : "N/A",
          hasCheckbox: false,
          checkboxCSS: [],
          usage: this.plan.usage,
          texts: [],
        },
        // {
        //   id: "XrayPremium",
        //   title: "Add Security Pack",
        //   price: this.premiumPrice(),
        //   hasCheckbox: true,
        //   checkboxGtmEvent: `${this.parentDataGtmPage}|xray-premium-checkbox`,
        //   checkboxState: this.plan.isForcedUpgrade,
        //   checkboxCSS: [],
        //   subscriptionType: "ENTERPRISE_X_MONTHLY",
        //   hideLine: true,
        //
        //   ...(this.plan.isUpgrade ? { hideLine: true, subscriptionPackageCss: ["mt-2"] } : {}),
        // },
      ];
      // if (this.plan.isUpgrade) {
      //   base.push({
      //     id: "total",
      //     title: "Total",
      //     price: this.totalPrice,
      //     hasCheckbox: false,
      //     checkboxGtmEvent: `${this.parentDataGtmPage}|xray-premium-total`,
      //     checkboxCSS: [],
      //   });
      // }
    }
    return base;
  }

  onUpdatePaymentType(checkboxData: SubscriptionPackageCheckBox) {
    const subscriptionPackage = this.subscriptionPackages.find(sub => sub.id === checkboxData.subscriptionPackageId);
    if (this.plan && this.plan.isUpgrade) {
      this.calculateTotal(checkboxData.selected);
    }
    this.$emit(
      "onUpdatePaymentType",
      checkboxData.selected && subscriptionPackage && subscriptionPackage.paymentType
        ? subscriptionPackage.paymentType
        : this.plan.paymentType,
    );
  }

  premiumPrice() {
    const { purchase, additionalTypes } = this.plan;
    if (!purchase || !additionalTypes) {
      return "N/A";
    }
    return this.$utils.round(parseInt(additionalTypes[0].price) - purchase.amount);
  }

  private calculateTotal(isSelected: boolean) {
    const { purchase, additionalTypes } = this.plan;

    if (!purchase || !additionalTypes) {
      this.totalPrice = "N/A";
      return;
    }

    this.totalPrice = isSelected ? additionalTypes[0].price : purchase.amount.toString();
  }

  created() {
    // set default total price
    if (this.plan && this.plan.isUpgrade) {
      this.calculateTotal(!!this.plan.isForcedUpgrade);
    }
  }
}
