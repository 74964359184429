
import { Component, Vue } from "vue-property-decorator";
import LightUpgradeCard from "@/components/views/subscriptions/lightUpgrade/LightUpgradeCard.vue";

@Component({
  name: "LightUpgradeSuccessAfterExpiration",
  components: { LightUpgradeCard },
})
export default class LightUpgradeSuccessReactivated extends Vue {
  closeWindow() {
    window.close();
  }
}
