
import { Component, Prop, Vue } from "vue-property-decorator";
import { PlanContext, PlanPurchase } from "@/types/localtypes";
import { PackageCore, PaymentType, SubscriptionTypes } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "PlanPackage",
})
export default class PlanPackage extends Vue {
  @Prop({ default: "Cloud Pro" })
  private title!: string;
  @Prop({ default: "" })
  private rateText!: string;
  @Prop({ default: () => undefined })
  private purchase!: PlanPurchase;
  @Prop({ default: false })
  private isSelected!: boolean;
  @Prop({ default: false })
  private isDisabled!: boolean;
  @Prop({ default: true })
  private isProcessable!: boolean;
  @Prop({ default: () => null })
  private onPlanSelected!: (title: string, core: PackageCore) => void;
  @Prop({ default: "SUBSCRIBE" })
  private buttonText!: string;
  @Prop({ default: undefined })
  private context!: PlanContext;
  @Prop({ default: () => [] })
  private additionalTypes!: SubscriptionTypes[];
  @Prop({ default: false })
  private isUpgrade!: boolean;
  @Prop({ default: false })
  private isPipelinesAvailable!: boolean;
  @Prop({ default: "MONTHLY" })
  private paymentType!: PaymentType;
  @Prop()
  private core!: PackageCore;

  notProcessableGtmEventLabel: string = "display-not-processable-explanation-message";

  get gtmEventTitle() {
    const context = `${this.context}-page`;
    const action = `${
      this.isProcessable
        ? this.$utils.toKebabCase(this.title, true) + "-" + this.$utils.toKebabCase(this.buttonText.trim())
        : this.notProcessableGtmEventLabel
    }`;
    return `${context}|${action}-button`;
  }

  get calcPrice() {
    // calculate plan price with pipelines if customer upgrades from subscription with pipelines.
    const pipelinesProX =
      this.additionalTypes && this.additionalTypes.find(type => type.optionType === "PIPELINES_PRO_X");
    if (this.isUpgrade && this.paymentType === "MONTHLY_PROX" && this.isPipelinesAvailable && this.purchase) {
      return pipelinesProX ? this.$utils.round(this.purchase.amount + parseInt(pipelinesProX.price), 0) : "N/A";
    }
    return this.purchase.amount;
  }

  onSelect() {
    this.onPlanSelected(this.title, this.core);
  }

  get buttonFade() {
    return { opacity: +!this.isSelected + "!important" };
  }
}
