
import LoadingMask from "@/components/common/LoadingMask.vue";
import pageRenderer from "@/components/views/subscriptions/modals/buyProcess/components/pageRenderer";
import HeaderForPurchase from "@/components/views/subscriptions/modals/headerPurchaseProcess/HeaderForPurchase.vue";

import { LoadingMaskProps } from "@/types/loadingMask";
import { FlatSubscription, PlanModel } from "@/types/localtypes";
import logger from "@/util/logger";
import { JFCheckBox } from "jfrog-ui-vue-essentials";
import {
  JPayPurchaseResponse,
  JPCInstance,
  PaymentType,
  SubscriptionDTO,
  SubscriptionTypes,
  UserDTO,
} from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({
  name: "BuyForm",
  $_veeValidate: { validator: "new" },
  components: {
    JFCheckBox,
    LoadingMask,
    HeaderForPurchase,
  },
})
export default class BuyForm extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Getter("user", { namespace: "user" })
  user!: UserDTO;
  @Getter("subscriptions", { namespace: "subscriptions" })
  subscriptions!: SubscriptionDTO[];
  @Action("setSubscriptions", { namespace: "subscriptions" })
  setSubscriptions!: (subscriptions: SubscriptionDTO[]) => void;
  @Action("addPaymentAccountNumber", { namespace: "user" })
  addPaymentAccountNumber!: (paymentAccountNumber: string) => void;
  @Getter("lightUiEnabled", { namespace: "application" })
  lightUiEnabled!: boolean;

  @Prop() private parentDataGtmPage!: string;
  @Prop({ default: "" })
  private ratePlanId!: string;
  @Prop({ default: "" })
  private newPaymentType!: string;
  @Prop({ default: null })
  private subscription!: FlatSubscription;
  @Prop({ default: null })
  private isFirstStep!: boolean;
  @Prop({ default: false })
  private eventBus!: Vue;
  @Prop({ default: false })
  private isFinishedForm!: boolean;
  @Prop({ default: 300 })
  private minHeight!: number;
  @Prop({ default: [] })
  private subscriptionTypes!: SubscriptionTypes[];
  @Prop({ default: () => [] })
  private additionalTypes!: SubscriptionTypes[];
  @Prop({ default: null })
  private selectedPlan!: PlanModel;

  @Watch("purchasePipelines")
  onAddPipelines() {
    const additionalPlansSelect = document.getElementById(
      "payment-object-wrapper-buy-processjpc-input-additionalRatePlanIds",
    );
    if (additionalPlansSelect === null) {
      return;
    }
    (additionalPlansSelect as HTMLSelectElement).value = this.additionalRatePlanIds[0];
  }

  buyFormDisplayed = false;
  paymentObjectWrapperId = "payment-object-wrapper-buy-process";
  isLoading = true;
  loadingText = "Loading form...";
  hasError = false;
  errorMessage = "";
  paymentScript = "";
  jpcSuccessMessage = "";
  jpcErrorMessage = "";
  jpcLoaded = false;
  purchasePipelines = false;
  hideCompanyInterval!: number;
  selectedPaymentType = "";
  currentRatePlanId = "";

  get isProTeam() {
    return this.selectedPlan.paymentType === "PRO_TEAM_MONTHLY";
  }

  onPurchasePipelines(purchasePipelines: boolean) {
    this.purchasePipelines = purchasePipelines;
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  get canPurchasePipelines() {
    const { isPipelinesAvailableInRegion, isJfrogUnified } = this.subscription.meta;
    return isPipelinesAvailableInRegion && isJfrogUnified;
  }

  get buyFormWrapperStyle() {
    return {
      minHeight: this.minHeight + "px",
    };
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
      zIndex: 10000,
    };
  }

  get isRow() {
    return this.isFirstStep ? "row" : "w-100";
  }

  setLoading(loading: boolean, shouldActivateButton: boolean = true) {
    this.isLoading = loading;
    if (shouldActivateButton) {
      this.$emit("onLoadingChange", loading);
    }
  }

  async mounted() {
    this.$jfUsers.redirectIfNotSync();
    // @ts-ignore
    if (window.JPC) {
      this.jpcLoaded = true;
    } else {
      try {
        this.paymentScript = await this.$jfPayments.fetchPaymentsScripts();
        if (this.paymentScript) {
          eval(this.paymentScript);
          this.jpcLoaded = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.hasError = true;
        this.notifyError(this.$jfMessages.jpay_loading_failed);
        this.$jfModal.dismiss();
      }
    }

    if (this.jpcLoaded) {
      this.loadJPCBuyForm();
    }
    this.loadInitialData();
  }
  loadInitialData() {
    this.selectedPaymentType = this.newPaymentType;
    this.currentRatePlanId = this.ratePlanId;
  }

  hideCompanyField() {
    this.hideCompanyInterval = setInterval(() => {
      this.tryToHideField("payment-object-wrapper-buy-processjpc-details-company");
    }, 10);
  }

  tryToHideField(id: string) {
    const fieldToHideHtemlEl = document.getElementById(id) as HTMLElement;
    if (!!fieldToHideHtemlEl) {
      fieldToHideHtemlEl.classList.add("hidden");
      clearInterval(this.hideCompanyInterval);
    }
  }

  loadJPCBuyForm() {
    // @ts-ignore
    const jpc: JPCInstance = new JPC(
      this.getJPCPrepopulate(),
      this.getJPCMessages(),
      this.getJPCOptions(),
      this.loadJPCCallbacks(),
    );

    jpc.loadBuyCustomerDetailsForm();
    if (!!this.user.company) {
      this.hideCompanyField();
    }
  }

  getJPCOptions(): JPCInstance["options"] {
    return {
      divJpcFormId: this.paymentObjectWrapperId,
      placeHoldersActive: false,
      appSessionId: this.$jfUsers.getSessionId(),
      creditCardIframeWidth: null,
      creditCardIframeHeight: 380,
    };
  }

  getJPCPrepopulate(): JPCInstance["prepopulate"] {
    const { firstName, lastName, email, company, phone } = this.user;

    return {
      firstName: firstName,
      lastName: lastName,
      email: email,
      confirmEmail: email,
      phone: phone,
      country: "",
      city: "",
      state: "",
      address: "",
      zip: "",
      vatId: null,
      company: company,
      billingFirstName: "",
      billingLastName: "",
      billingEmail: "",
      billingConfirmEmail: "",
      billingPhone: "",
      billingAddress: "",
      billingZip: "",
      billingState: null,
      billingCountry: "",
      billingCity: "",
      billingCompany: "",
      ratePlanId: this.ratePlanId,
      additionalRatePlanIds: this.additionalRatePlanIds,
    } as any;
  }

  get additionalRatePlanIds(): string[] {
    return this.purchasePipelines && this.additionalTypes.length > 0 ? [this.additionalTypes[0].ratePlanId] : [];
  }

  getJPCMessages(): JPCInstance["messages"] {
    const subscriptionType =
      this.subscriptionTypes && this.subscriptionTypes.find(value => value.eventSubscriptionType === "CLOUD_JCR");
    const ratePlanId = subscriptionType && subscriptionType.ratePlanId;
    return (this.ratePlanId === ratePlanId && {
      label_amount: "Base Price",
    }) as any;
  }

  onUpdatePaymentType(paymentType: PaymentType) {
    this.selectedPaymentType = paymentType;

    if (this.additionalTypes.length === 0) {
      return logger.warn("additional types are empty");
    }
    this.currentRatePlanId =
      paymentType === this.additionalTypes[0].paymentType ? this.additionalTypes[0].ratePlanId : this.ratePlanId;
  }

  get buyFromExpiredTrial() {
    return this.subscription.meta.isExpiredTrial;
  }

  async purchaseCloud(response: JPayPurchaseResponse): Promise<void> {
    try {
      this.setLoading(true);
      const subscriptionResponse: SubscriptionDTO = await this.$jfSubscriptions.purchaseCloud(
        response,
        this.subscription,
        this.selectedPaymentType,
        this.isProTeam ? false : this.purchasePipelines,
      );
      // Update Vuex subscriptions with the new one received after buy process
      await this.setSubscriptions(
        this.subscriptions.map(subscription =>
          subscriptionResponse.accountNumber === subscription.accountNumber ? subscriptionResponse : subscription,
        ),
      );
      // reload user for company update, no need to await.
      this.$jfUsers.reloadUser();

      // Update Vuex user metadata
      await this.addPaymentAccountNumber(subscriptionResponse.paymentAccountNumber);
      this.$jfWebeyez.send({ goal_key: "upgrade", isSuccess: true });

      if (this.lightUiEnabled) {
        this.handleLightUpgradeSuccess();
      } else {
        const notificationMessage = this.buyFromExpiredTrial
          ? this.$jfMessages.subscriptions_buy_from_expired_success
          : this.$jfMessages.subscriptions_buy_success;
        this.$jfNotification.success({ text: notificationMessage });
        this.globalBus.$emit("shouldConvertNotificationSpanRouterLink");
        this.$router.push({ path: `/subscriptions`, params: { accountNumber: "" + this.subscription.accountNumber } });
      }
    } catch (e) {
      this.$log.error(e);
      this.notifyError(this.$jfMessages.subscriptions_buy_error_unknown);
    } finally {
      this.setLoading(false);
      this.$jfModal.dismiss();
    }
  }

  handleLightUpgradeSuccess() {
    this.globalBus.$emit("lightUpgradeSuccess", { buyFromExpiredTrial: this.buyFromExpiredTrial });
    this.dismiss();
  }

  createEvents() {
    const countrySelect = document.querySelector(pageRenderer.country);
    const countryBillingSelect = document.querySelector(pageRenderer.countryBilling);
    // additional billing select starts of with hidden and disabled field.
    // const additionalBillingDiv = document.querySelector(pageRenderer.additionalBilling);
    const additionalBillingSelect = document.querySelector(pageRenderer.additionalBillingSelect);

    if (!countrySelect || !countryBillingSelect) {
      return;
    }
    countrySelect.addEventListener("change", () => {
      pageRenderer.pushOptions(pageRenderer.state, "options");
      this.hideStateIfNotNeeded();
    });
    countryBillingSelect.addEventListener("change", () =>
      pageRenderer.pushOptions(pageRenderer.stateBilling, "options"),
    );
    if (!additionalBillingSelect) {
      return;
    }
    // additionalBillingDiv.classList.toggle("jpc-hidden-field");
    (additionalBillingSelect as HTMLSelectElement).disabled = false;
  }

  scrollIfError() {
    const elements = document.getElementsByClassName("jpc-error");
    for (let i = 0; i < elements.length; i++) {
      const elem = elements[i];
      if (elem.innerHTML && elem.parentElement) {
        this.$log.error(`Element with ID '${elem.id}' is in error : ${elem.innerHTML}`);
        return elem.parentElement.scrollIntoView(true);
      }
    }
  }

  scrollTo(elem: HTMLElement | null) {
    if (!elem || !elem.parentElement) {
      return;
    }
    elem.parentElement.scrollIntoView(true);
  }

  hideStateIfNotNeeded() {
    const stateContainer = "payment-object-wrapper-buy-processjpc-details-state";
    var stateInput = document.getElementById("payment-object-wrapper-buy-processjpc-input-state") as HTMLSelectElement;
    stateInput && stateInput.disabled ? this.hideState(stateContainer) : this.showState(stateContainer);
  }

  hideState(id: string) {
    const stateDiv = document.getElementById(id) as HTMLDivElement;
    if (!stateDiv) {
      return;
    }
    stateDiv.classList.add("jpc-hidden-field");
  }

  showState(id: string) {
    const stateDiv = document.getElementById(id) as HTMLDivElement;
    if (!stateDiv) {
      return;
    }
    stateDiv.classList.remove("jpc-hidden-field");
  }

  get jpcLogErrorPrefix() {
    return `jpc-callback-purchase-process [${this.subscription.accountNumber}] : `;
  }

  loadJPCCallbacks(): JPCInstance["callbacks"] {
    return {
      buyCustomerDetailsForm_hideOverlayFor3DS: () => {
        setTimeout(() => {
          this.setLoading(false, false);
        }, 3000);
      },
      buyCustomerDetailsForm_render(html: HTMLDivElement) {
        return pageRenderer.renderPage(html);
      },
      buyCustomerDetailsForm_submitCheckoutSuccess: (response: JPayPurchaseResponse) => {
        this.jpcSuccessMessage = "<pre>Payment Success.</pre><br/>";
        this.purchaseCloud(response);
      },
      buyCustomerDetailsForm_loadSuccess: () => {
        this.buyFormDisplayed = true;
        pageRenderer.renderSelect();
        this.createEvents();
        this.hideStateIfNotNeeded();
        this.loadingText = "";
        this.scrollTo(document.querySelector("#payment-object-wrapper-buy-process"));
        this.setLoading(false);
      },
      buyCustomerDetailsForm_loadError: (status: number, errorMessage: string) => {
        this.buyFormDisplayed = false;
        const jpcErrorMessage = `${this.jpcLogErrorPrefix}Error : ${status} => ${errorMessage || "no message"}`;
        this.$log.error(jpcErrorMessage);
        this.setLoading(false);
        if (status === 401) {
          this.$jfUsers.logout(true);
          return;
        }
        this.notifyError(this.$jfMessages.jpay_buy_form_loading_failed);
      },
      buyCustomerDetailsForm_submitError: (status: number, errorMessage: string, exceptionClass: string) => {
        const jpcErrorMessage = `${this.jpcLogErrorPrefix}Error : ${status} => ${errorMessage || "no message"}${
          exceptionClass ? ` (exception : ${exceptionClass})` : ""
        }`;

        this.$log.error(jpcErrorMessage);
        this.setLoading(false);
        if (status === 401) {
          this.$jfUsers.logout(true);
          return;
        }
        if (exceptionClass && exceptionClass.includes("TaxValidationErrorException")) {
          // we don't show error notification because JPay will display UI errors into the form.
          return;
        }
        this.notifyError(this.$jfMessages.jpay_buy_form_submit_failed);
      },
      buyCustomerDetailsForm_submitCheckoutError: (message: string) => {
        this.$log.error(`${this.jpcLogErrorPrefix}${message}`);
        this.setLoading(false);

        this.notifyError(this.$jfMessages.extractPaymentsError(message));
      },
      buyCustomerDetailsForm_beforeSubmitCheckout: () => {
        this.setLoading(true);
        return true;
      },
      buyCustomerDetailsForm_sameInfosClicked: () => {
        pageRenderer.forceSelect(pageRenderer.countryBilling, "forceSelect");
        pageRenderer.pushOptions(pageRenderer.stateBilling, "options");
        pageRenderer.forceSelect(pageRenderer.stateBilling, "forceSelect");
      },
      buyCustomerDetailsForm_beforeSubmit: () => {
        this.setLoading(true);
        return true;
      },

      buyCustomerDetailsForm_beforeCheckForm: () => {
        this.$nextTick(() => this.scrollIfError());
        return true;
      },

      buyCustomerDetailsForm_beforeChangePage: () => {
        this.$emit("isFirstStep", false);
        return true;
      },
      buyCustomerDetailsForm_afterChangePage: () => {
        this.setLoading(false);
        return true;
      },
      buyCustomerDetailsForm_beforePrevCheckout: () => {
        return true;
      },
      buyCustomerDetailsForm_transformRatePlanId: (ratePlanId: string) => {
        return this.currentRatePlanId;
      },
      global_selectStateChanged: (select: string) => {},
      global_selectCountryChanged: (select: string) => {},
    };
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
    this.$jfWebeyez.send({ goal_key: "upgrade", isSuccess: false, errorMessage: errorMessage });
  }

  destroyed() {}
}
