
import PageSection from "@/components/layout/PageSection.vue";
import PageView from "@/components/layout/PageView.vue";
import { QuickPurchaseData } from "@/types/localtypes";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "PromoPT15USDHeader",
  components: { PageView, PageSection },
})
export default class PromoPT15USDHeader extends Vue {
  @Getter("quickPurchaseData", { namespace: "quickPurchase" })
  quickPurchaseData!: QuickPurchaseData;

  get serverName() {
    return (this.quickPurchaseData && this.quickPurchaseData.serverName) || null;
  }

  get hasServerName() {
    return !!this.serverName;
  }

  get quickPurchaseHeaderWrapperClasses() {
    const classes: string[] = [this.$mq];
    return classes;
  }
}
