
import { PlanContent } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";
import { JFEssentialsPopupMenuPlacement } from "@/types/3rdPartyLibs";

@Component({
  name: "PlanSlot",
})
export default class PlanSlot extends Vue {
  @Prop({ default: () => null })
  private item!: PlanContent;

  tooltipHelperPointer(trigger: boolean) {
    return trigger ? "hover" : "";
  }

  contentIconClasses(content: PlanContent) {
    const classes: string[] = [];

    if (content.icon) {
      classes.push(content.icon.name);
      if (content.icon.fontSize) {
        classes.push(`fs-${content.icon.fontSize}`);
      }
      if (content.icon.cssClass) {
        classes.push(content.icon.cssClass);
      }
    }
    return classes;
  }

  contentTitleClasses(content: PlanContent) {
    const classes: string[] = [];

    if (content.titleBold) {
      classes.push("bold");
    }
    return classes;
  }

  get tooltipProps() {
    if (this.item.tooltip) {
      const placement: JFEssentialsPopupMenuPlacement = "top-start";
      return {
        html: true,
        content: this.item.tooltip.content,
        placement: placement,
        autoHide: false,
        hideOnTargetClick: false,
      };
    }
    return {};
  }
}
