
import { CarouselPropertie, CarouselProps, InitialCarouselState } from "@/types/carousel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "Carousel",
})
export default class Carousel extends Vue {
  @Prop() private components!: CarouselProps["components"];
  @Prop() private initialState!: CarouselProps["initialState"];
  @Prop({ default: false })
  private hideMenu!: CarouselProps["hideMenu"];
  @Prop({ default: undefined })
  private context!: CarouselProps["context"];
  currentIndex = 0;
  refName = "slide";
  MORE_OPTIONS_TEXT = "View All Offers";
  // LESS_OPTIONS_TEXT = "View less Options";
  localComponents: CarouselPropertie[] = [];
  leftMargin = 5;
  refsAreLoaded = false;

  onChildMounted() {
    this.refsAreLoaded = true;
  }

  get carouselLegendClasses() {
    const classes: string[] = [this.$mq];
    if (!this.activateSliderFunctions) {
      classes.push("full-width");
    }
    return classes;
  }

  get containerClasses() {
    const classes: string[] = [];
    if (!this.isMobile) {
      classes.push("fxNoWrap");
    }
    return classes;
  }

  get hasComponents() {
    return this.components && this.components.length > 0;
  }

  get activateSliderFunctions() {
    return this.hasComponents && !this.isMobile && !this.hideMenu;
  }

  get componentWidthPercent() {
    if (this.isMobile) {
      return 100;
    }
    if (this.isTablet) {
      return 80;
    }
    if (this.isLaptop) {
      return 60;
    }
    return 45;
  }

  componentStyle(c: CarouselPropertie) {
    const componentWidthPercent = this.componentWidthPercent;
    const idleComponentWidth = (window.innerWidth * componentWidthPercent) / 100;
    return c.visible && this.singleMode
      ? {
          width: window.innerWidth + "px",
          minWidth: window.innerWidth + "px",
          marginRight: "",
          zIndex: 1,
        }
      : {
          width: idleComponentWidth + "px",
          minWidth: idleComponentWidth + "px",
          marginRight: this.leftMargin + "px",
          zIndex: 0,
        };
  }
  componentClasses(c: CarouselPropertie) {
    let classes = [this.$mq, "component-wrapper"];
    if (c.visible) {
      classes.push("visible");
    }
    return classes;
  }

  get singleMode() {
    return (this.localComponents && this.localComponents.filter(c => c.visible).length === 1) || false;
  }

  @Watch("components")
  onComponentChange(components: CarouselPropertie[]) {
    this.localComponents = components;
  }
  @Watch("initialState")
  onStateChange(initialState: InitialCarouselState) {
    this.refreshState();
  }
  refreshState() {
    this.currentIndex = this.initialState.initialIndex;
    const showAll = this.isMobile ? true : this.initialState.showAll;
    this.localComponents = this.localComponents.map((c, i) => ({
      ...c,
      visible: showAll ? true : i === this.currentIndex,
    }));

    if (!this.localComponents.find(c => c.visible)) {
      this.$log.warn("All carousel displayed components have visible = false.");
    }
  }

  get canPreviousSlide() {
    return !(this.singleMode || this.currentIndex === 0);
  }

  get canNextSlide() {
    return !(
      this.singleMode || this.currentIndex === this.localComponents.length - (this.componentWidthPercent >= 50 ? 1 : 2)
    );
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get isTablet() {
    return this.$mq === "tablet";
  }
  get isLaptop() {
    return this.$mq === "laptop";
  }

  prevSlide() {
    if (this.canPreviousSlide) {
      this.currentIndex--;
    }
  }

  nextSlide() {
    if (this.canNextSlide) {
      this.currentIndex++;
    }
  }

  get optionsText() {
    return this.MORE_OPTIONS_TEXT;
    // return this.singleMode ? this.MORE_OPTIONS_TEXT : this.LESS_OPTIONS_TEXT;
  }

  showAll() {
    this.localComponents = this.localComponents.map(c => ({ ...c, visible: true }));
  }

  showOne() {
    this.currentIndex = this.initialState.initialIndex;
    this.localComponents = this.localComponents.map((c, i) => ({
      ...c,
      visible: i === this.initialState.initialIndex,
    }));
  }

  toggleOptions() {
    this.singleMode ? this.showAll() : this.showOne();
  }

  get leftXAxisPixel() {
    if (this.isMobile || !this.refsAreLoaded) {
      return 0;
    }
    let shift = 0;
    for (let i = 0; i < this.currentIndex; i++) {
      const slide: Vue = (this.$refs[this.refName + i] as Vue[])[0];
      shift += slide ? slide.$el.clientWidth + this.leftMargin : 0;
    }
    return shift;
  }

  get slideStyle() {
    return { left: -this.leftXAxisPixel + "px" };
  }
}
