
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { GetAQuoteRequest, JpuDTO, UserDTO } from "@jfrog-ba/myjfrog-common";
import { JFFormControl, JFTextAreaField, JFTextField } from "jfrog-ui-vue-essentials";
import { LoadingMaskProps } from "@/types/loadingMask";
import LoadingMask from "@/components/common/LoadingMask.vue";
import { PlanModel } from "@/types/localtypes";

@Component({
  name: "ModalGetAQuote",
  $_veeValidate: { validator: "new" },
  components: {
    JFTextField,
    JFFormControl,
    JFTextAreaField,
    LoadingMask,
  },
})
export default class ModalGetAQuote extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Getter("user", { namespace: "user" })
  user!: UserDTO;
  @Prop() private actionType!: GetAQuoteRequest["actionType"];
  @Prop() private serverName!: JpuDTO["serverName"];
  @Prop() private isEnterprisePlus!: boolean;
  @Prop() private title!: string;
  @Prop() private core!: PlanModel["core"];

  isLoading: boolean = false;
  comments: string = "";

  get loadingMaskProps(): LoadingMaskProps {
    return { loading: this.isLoading };
  }

  get explanationsText() {
    return `Hi ${this.user.firstName},<br/>Thank you for your interest in ${this.title}, one of our representatives will contact you.`;
  }

  get canSendForm() {
    return !this.isLoading && !this.errors.any();
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
  }

  async sendForm() {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    try {
      this.isLoading = true;
      const request: GetAQuoteRequest = {
        marketoCookie: this.$jfMarketo.getCookie(),
        actionType: this.actionType,
        serverName: this.serverName,
        comments: this.comments,
        reason: "get_a_quote",
        isEnterprisePlus: this.isEnterprisePlus ? this.isEnterprisePlus.toString() : "",
        core: this.core,
      };
      await this.$jfSubscriptions.sendGetAQuote(request);
      this.$jfNotification.success({
        text: this.$jfMessages.subscriptions_request_getaquote_success,
      });
      this.dismiss();
    } catch (e) {
      this.$log.error(e);
      this.notifyError(this.$jfMessages.subscriptions_request_getaquote_error);
    } finally {
      this.isLoading = false;
    }
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
  }
}
