
import JFLine from "@/components/common/JFLine.vue";
import { PlanModel } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";
import { PackageCore } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "PlanTitle",
  components: {
    JFLine,
  },
})
export default class PlanTitle extends Vue {
  @Prop({ default: "Cloud Pro" })
  private title!: string;
  @Prop({ default: "" })
  private usage!: PlanModel["usage"];
  @Prop()
  private core!: PackageCore;

  get isMobile() {
    return this.$mq === "mobile";
  }

  get isDesktop() {
    return this.$mq === "desktop";
  }
  get isDevSecOps() {
    return this.core === "DEVSECOPS";
  }

  get mutatedTitle() {
    if (!this.title) {
      return [""];
    }
    const title = this.title.split(" ");
    // if we have a title which should be in 2 rows we should do like above (old "CLOUD ..' plans)
    // return [title.shift(), title.join("&nbsp;")];
    return [title.join("&nbsp;")];
  }

  lineHeight(value: string) {
    return this.$mq === "mobile" ? "80%" : value;
  }
}
