
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "JFLine",
})
export default class JFLine extends Vue {
  @Prop({ default: "50px" })
  private width!: string;
  @Prop({ default: "1px" })
  private height!: string;
  @Prop({ default: "#d9d9d9" })
  private backgroundColor!: string;

  get style() {
    return `width:${this.width}; backgroundColor: ${this.backgroundColor}; height:${this.height}`;
  }
}
