
import JFLine from "@/components/common/JFLine.vue";
import PlanPackage from "@/components/pricingTable/PlanPackage.vue";
import PlanSlot from "@/components/pricingTable/PlanSlot.vue";
import PlanTitle from "@/components/pricingTable/PlanTitle.vue";
import PulseSpinner from "@/components/spinners/PulseSpinner.vue";
// import { PulseSpinnerProperties } from "@/types/spinners";
import { PlanContext, PlanModel, PlanPurchase } from "@/types/localtypes";
import { PackageCore, PaymentType, SubscriptionTypes } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "Plan",
  components: {
    PlanPackage,
    JFLine,
    PulseSpinner,
    PlanTitle,
    PlanSlot,
  },
})
export default class Plan extends Vue {
  @Prop({ default: () => undefined })
  private purchase!: PlanPurchase;
  @Prop({ default: "Cloud Pro" })
  private title!: string;
  @Prop({ default: "Annual Base Rate" })
  private rateText!: string;
  @Prop({ default: false })
  private isSelected!: boolean;
  @Prop({ default: () => null })
  private onPlanSelected!: () => void;
  @Prop({ default: "" })
  private buttonText!: string;

  @Prop({ default: "dark-shadow" })
  private shadowType!: string;
  @Prop() private calculatorPaymentType!: PaymentType;
  @Prop({ default: false })
  private isCurrent!: boolean;
  @Prop({ default: false })
  private isTrial!: boolean;
  @Prop({ default: false })
  private isDisabled!: boolean;
  @Prop({ default: true })
  private isProcessable!: boolean;
  @Prop({ default: undefined })
  private context!: PlanContext;
  @Prop({ default: false })
  private isUpgrade!: boolean;
  @Prop({ default: false })
  private isPipelinesAvailable!: boolean;
  @Prop({ default: "MONTHLY" })
  private paymentType!: PaymentType;

  @Prop({ default: () => ["Unlimited Repositories"] })
  private titleText!: string[];
  @Prop({ default: () => [] })
  private usage!: PlanModel["usage"][];
  @Prop({ default: () => [{ text: "data", borderBottom: true }] })
  private content!: [{ text: string; hideBorderBottom?: boolean }];
  @Prop({ default: false })
  private viewLess!: boolean;
  @Prop({ default: () => [] })
  private additionalTypes!: SubscriptionTypes[];
  @Prop()
  private core!: PackageCore;
  @Getter("lightUiEnabled", { namespace: "application" })
  lightUiEnabled!: boolean;

  contentHeight = 0;

  mounted() {
    this.$emit("onChildMounted", this.$el.clientWidth);
    const virtualPaddingBottom = 30;
    this.contentHeight = this.$el.clientHeight - 130 - 50 - virtualPaddingBottom;
  }
  // tiersPrices: PricesTiersResponse["ratePlanChargeMap"] | null = null;

  /**** moved calculator data from parent to plan  ****/
  // get loadingCalculatorSpinnerProps(): PulseSpinnerProperties {
  //   return {
  //     show: !this.tiersPricesLoaded,
  //     size: "xxxs",
  //   };
  // }

  // get tiersPricesLoaded() {
  //   return !!this.tiersPrices;
  // }

  get getOneItemWidth() {
    if (this.isLaptop) {
      return "60%";
    }
    if (this.isDesktop || this.isLaptopXL) {
      return "50%";
    }

    return "100%";
  }

  calcFlex(id: string, isTitle: boolean) {
    if (id !== "security") {
      return [];
    }
    if (!this.isDesktop) {
      if (isTitle) {
        return ["fx1"];
      }
      return ["fx4"];
    }
  }

  get shrinkComponentStyle() {
    return { width: this.viewLess ? this.getOneItemWidth : "100%" };
  }

  get isLaptop() {
    return this.$mq === "laptop";
  }

  get isLaptopXL() {
    return this.$mq === "laptopXL";
  }

  get isDesktop() {
    return this.$mq === "desktop";
  }

  get containerClass() {
    let classes: string[] = [this.$mq];
    if (this.isMobile) {
      classes.push("pl-2");
    } else {
      classes.push("pl-4");
    }
    if (this.isDisabled) {
      classes.push("is-disabled-plan");
    }
    return classes;
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get isCurrentPlanCss() {
    return `plan-meta-title ${this.isCurrent ? "current-plan" : ""}`;
  }

  get contentStyle() {
    if (this.isMobile) {
      return { margin: "5px" };
    }
    const computedHeight = this.lightUiEnabled
      ? `calc(100vh - 200px - 108px - 72px)`
      : `${this.contentHeight}px !important`;
    return { height: computedHeight };
  }

  handleCalculatorClick() {
    this.$emit("openCalculator", this.calculatorPaymentType);
  }

  // get monthlyRateplanId() {
  //   const subscriptionType = this.subscriptionTypes.find(type => type.subscriptionType === "MONTHLY");
  //   if (subscriptionType) {
  //     return subscriptionType.ratePlanId;
  //   }
  //   return null;
  // }
  // get monthlyProXRateplanId() {
  //   const subscriptionType = this.subscriptionTypes.find(type => type.subscriptionType === "MONTHLY_PROX");
  //   if (subscriptionType) {
  //     return subscriptionType.ratePlanId;
  //   }
  //   return null;
  // }

  // mounted() {
  /**** NOTE - Decomment for pricing calculator ****/

  //   try {
  //     const monthlyRateplanId = this.monthlyRateplanId;
  //     const monthlyXRateplanId = this.monthlyProXRateplanId;
  //
  //     if (monthlyRateplanId && monthlyXRateplanId) {
  //       const response = await this.$jfSubscriptions.getPricesTiers({
  //         ratePlanIds: [monthlyRateplanId, monthlyXRateplanId],
  //       });
  //       this.tiersPrices = response.ratePlanChargeMap;
  //     } else {
  //       throw new Error("Required ratePlans not found");
  //     }
  //   } catch (error) {
  //     this.notifyError(this.$jfMessages.subscriptions_buy_error_calculator_error);
  //   }
  // }

  // handleOpenCalculator(initialCalculatorPaymentType: PaymentType) {
  //   if (this.tiersPricesLoaded) {
  //     this.$jfModal.showCustom(
  //       ModalPricingCalculator,
  //       "md+-full-height",
  //       {
  //         tiersPrices: this.tiersPrices,
  //         initialPaymentType: initialCalculatorPaymentType,
  //         subscriptionTypes: this.subscriptionTypes,
  //         onlyPro: !this.subscription.xrayAvailableInRegion || this.subscription.cloudProviderCode === "azure",
  //         onApprove: (estimatedPaymentType: PaymentType) => {
  //           const planToTrigger = this.displayedPlans.find(
  //             (plan: PlanModel) => plan.calculatorPaymentType === estimatedPaymentType,
  //           );
  //           if (planToTrigger) {
  //             this.selectPlan(planToTrigger.code);
  //           }
  //         },
  //       },
  //       {
  //         forceDisplayTitle: true,
  //         headerBorder: false,
  //         footerBorder: false,
  //         clickShouldClose: false,
  //         shakeIfCantClose: false,
  //       },
  //     );
  //   }
  // }
}
