
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { SubscriptionTypes } from "@jfrog-ba/myjfrog-common";
import CustomModalHeader from "@/components/common/CustomModalHeader.vue";
import LoadingMask from "@/components/common/LoadingMask.vue";
import PageSection from "@/components/layout/PageSection.vue";
import PageTitle from "@/components/layout/PageTitle.vue";
import PageView from "@/components/layout/PageView.vue";
import BuyForm from "@/components/views/subscriptions/modals/buyProcess/components/BuyForm.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { FlatSubscription, PlanModel } from "@/types/localtypes";
// import ModalPricingCalculator from "@/components/pricingCalculator/modals/ModalPricingCalculator.vue";
// import { PaymentType, PricesTiersResponse, SubscriptionTypes } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "ModalBuy",
  components: {
    CustomModalHeader,
    PageSection,
    PageView,
    PageTitle,
    BuyForm,
    LoadingMask,
  },
})
export default class ModalBuy extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Prop({ default: null })
  private subscription!: FlatSubscription;
  @Prop({ default: null })
  private selectedPlan!: PlanModel;
  @Prop({ default: [] })
  private subscriptionTypes!: SubscriptionTypes[];
  // @Prop() private onBuyTrial!: () => void;
  isFirstStep = true;
  innerComponentIsLoading = false;
  eventBus = new Vue();
  // subscriptionTypes: SubscriptionTypes[] = [];
  modalContentMinHeight: number = 300;
  errorRecentlyReceived = false;
  loadingData = false;

  get dataGtmPage() {
    const planName = this.$utils.toKebabCase(this.selectedPlan.title, true);
    return `buy-process-${planName}`;
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading && this.loadingData,
    };
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get buyModalBodyStyle() {
    const loaderHeight = 40;
    const customHeaderHeight = 65;
    const customFooterHeight = 60;
    const modalVerticalMargin = this.isMobile ? 0 : 50;

    this.modalContentMinHeight =
      document.body.clientHeight - modalVerticalMargin * 2 - customHeaderHeight - customFooterHeight - loaderHeight / 2;
    return {
      minHeight: this.modalContentMinHeight + "px",
    };
  }

  get previousBtnText() {
    return this.isFirstStep ? "cancel" : "previous";
  }
  get nextBtnText() {
    return this.isFirstStep ? "next" : "confirm";
  }

  get calcTitle(): string {
    if (!this.selectedPlan) {
      return "";
    }
    return this.selectedPlan.title;
  }

  onPrev() {
    const btnPrev = document.getElementById("payment-object-wrapper-buy-processjpc-btn-prev");
    if (btnPrev && !this.isFirstStep) {
      this.isFirstStep = true;
      btnPrev.click();
    }
  }

  onInnerComponentLoadingChange(innerComponentIsLoading: boolean) {
    this.innerComponentIsLoading = innerComponentIsLoading;
  }

  confirm() {
    this.$jfUsers.redirectIfNotSync();
    const btnNext = document.getElementById("payment-object-wrapper-buy-processjpc-btn-next");
    const btnSubmit = document.getElementById("payment-object-wrapper-buy-processjpc-btn-submit");
    const sameInfoCheckBox = document.getElementById(
      "payment-object-wrapper-buy-processjpc-checkbox-sameInfo",
    ) as HTMLInputElement;

    if (!sameInfoCheckBox) {
      return;
    }
    if (sameInfoCheckBox.checked) {
      sameInfoCheckBox.click();
    }
    sameInfoCheckBox.click();

    if (btnNext && this.isFirstStep) {
      btnNext.click();
    }
    if (btnSubmit && !this.isFirstStep) {
      btnSubmit.click();
    }
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  onCancel() {
    this.$jfUsers.redirectIfNotSync();
    this.dismiss();
    this.isFirstStep = true;
  }

  toggleFirstStep(val: boolean) {
    this.isFirstStep = val;
  }

  async mounted() {
    this.$jfModal.childComponentIsSet();
  }

  get isLoading() {
    return !this.selectedPlan;
  }
}
