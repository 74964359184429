
import JFLine from "@/components/common/JFLine.vue";
import { SubscriptionPackageCheckBox, SubscriptionPackageType } from "@/types/localtypes";
import { JFCheckBox } from "jfrog-ui-vue-essentials";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "subscriptionPackage",
  components: {
    JFLine,
    JFCheckBox,
  },
})
export default class SubscriptionPackage extends Vue {
  @Prop({ default: null })
  private subscriptionPackage!: SubscriptionPackageType;

  checkboxModel = false;
  get checkBoxName() {
    return `title-subscriptionPackage${this.subscriptionPackage.title}`;
  }

  onChange(selected: boolean) {
    this.$emit("updatePaymentType", {
      subscriptionPackageId: this.subscriptionPackage.id,
      selected,
    } as SubscriptionPackageCheckBox);
  }
  mounted() {
    this.checkboxModel = !!this.subscriptionPackage && !!this.subscriptionPackage.checkboxState;
  }
}
